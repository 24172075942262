import classNames from 'clsx';
import noop from 'lodash/noop';
import PropTypes from 'prop-types';
import { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import {
  MEDIA_AD_SUBTITLE,
  MEDIA_AD_TITLE,
} from 'src/common/constants/localizations/player';
import { openNowPlayingDialog } from '../../../actions/dialog';
import { LocationAndLocalizationContext } from '../../../providers/LocationAndLocalizationProvider';
import { selectIsDiscord } from '../../../selectors/app';
import { selectIsDesktopNowPlayingEnabled } from '../../../selectors/config';
import { selectIsNowPlayingDialogOpen } from '../../../selectors/dialog';
import { selectNowPlayingRejectReasonKey } from '../../../selectors/player';
import assetUrl from '../../../utils/assetUrl';
import ImageWithDefault from '../../shared/ImageWithDefault';
import css from './nowPlaying.module.scss';

// this is the width of the right-hand title gradient, defined for .link:after
// in nowPlaying.module.scss
const RIGHT_SIDE_TITLE_GRADIENT_WIDTH = 10;
const MOBILE_SCROLL_TIMEOUT = 5000;

// exported for testing
export class NowPlaying extends Component {
  static propTypes = {
    nowPlaying: PropTypes.object.isRequired,
    nowPlayingPathname: PropTypes.string,
    isMobile: PropTypes.bool.isRequired,
    isMediaAdLoaded: PropTypes.bool.isRequired,
    actions: PropTypes.object.isRequired,
    isDesktopNowPlayingEnabled: PropTypes.bool.isRequired,
    isNowPlayingDialogOpen: PropTypes.bool.isRequired,
    isDiscord: PropTypes.bool.isRequired,
    handleBoostClick: PropTypes.func.isRequired,
    shouldAllowSwitch: PropTypes.bool.isRequired,
    hasBoostAvailable: PropTypes.bool.isRequired,
    isBoostFeaturedInPlayer: PropTypes.bool.isRequired,
    disableTitleScroll: PropTypes.bool,
  };

  static contextType = LocationAndLocalizationContext;

  constructor(props) {
    super(props);

    this.scrollPosition = 0;
    this.outerContainer = createRef();
    this.scrollInner = createRef();

    this.startTitleScroll = this.startTitleScroll.bind(this);
    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.isTitleOverflowing = this.isTitleOverflowing.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { disableTitleScroll, isMobile, nowPlaying } = this.props;
    if (!disableTitleScroll && nowPlaying !== prevProps.nowPlaying) {
      this.resetTitleScroll();

      if (isMobile) {
        this.scrollTimeout = setTimeout(
          this.startTitleScroll,
          MOBILE_SCROLL_TIMEOUT,
        );
      }
    }
  }

  componentWillUnmount() {
    this.resetTitleScroll();
  }

  handleMouseEnter() {
    if (!this.props.isMobile && this.isTitleOverflowing()) {
      this.resetTitleScroll();
      this.startTitleScroll();
    }
  }

  handleArtworkClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.actions.openNowPlayingDialog();
  };

  isTitleOverflowing() {
    const scrollInner = this.scrollInner.current;
    const outerContainer = this.outerContainer.current;

    return (
      scrollInner.clientWidth >
      outerContainer.clientWidth - RIGHT_SIDE_TITLE_GRADIENT_WIDTH
    );
  }

  startTitleScroll() {
    const scrollInner = this.scrollInner.current;
    const outerContainer = this.outerContainer.current;
    const { isMobile } = this.props;

    if (this.scrollPosition <= -scrollInner.clientWidth) {
      // move to other side to scroll back into view
      this.scrollPosition = outerContainer.clientWidth;
    } else {
      // move left
      this.scrollPosition -= 0.5;
    }

    // pause at initial position on mobile devices
    if (this.scrollPosition === 0 && isMobile) {
      this.scrollTimeout = setTimeout(
        this.startTitleScroll,
        MOBILE_SCROLL_TIMEOUT,
      );
      return;
    }

    // end animation after 1 cycle on non-mobile devices
    if (this.isTitleScrolling && this.scrollPosition === 0 && !isMobile) {
      this.isTitleScrolling = false;
      return;
    }

    scrollInner.style.transform = `translate(${this.scrollPosition}px, -50%)`;
    this.animationId = window.requestAnimationFrame(this.startTitleScroll);
    this.isTitleScrolling = true;
  }

  resetTitleScroll() {
    clearTimeout(this.scrollTimeout);
    window.cancelAnimationFrame(this.animationId);
    this.scrollPosition = 0;
    this.scrollInner.current.style.transform = 'translate(0px, -50%)';
  }

  render() {
    const {
      isBoostFeaturedInPlayer,
      nowPlaying,
      nowPlayingPathname,
      isMediaAdLoaded,
      isMobile,
      actions,
      isDesktopNowPlayingEnabled,
      isNowPlayingDialogOpen,
      handleBoostClick,
      shouldAllowSwitch,
      hasBoostAvailable,
      isDiscord,
      nowPlayingRejectReasonKey,
    } = this.props;
    const { getLocalizedText } = this.context;

    const nowPlayingNode = (
      <div
        id="nowPlayingContainer"
        className={css.nowPlayingContainer}
        onClick={isDiscord ? this.handleArtworkClick : noop}
      >
        <div className={css.nowPlayingScrollSpacer}>
          <div
            className={classNames(css.artworkContainer, {
              [css.boostExperienceContainer]: hasBoostAvailable,
              [css.boostActive]: isBoostFeaturedInPlayer && hasBoostAvailable,
            })}
            data-testid="artworkContainer"
            onClick={
              hasBoostAvailable
                ? handleBoostClick
                : isDiscord
                  ? this.handleArtworkClick
                  : noop
            }
          >
            {shouldAllowSwitch && (
              <img
                className={css.boostCircleIcon}
                src={assetUrl('assets/img/shared/switch-circle-icon.svg')}
              />
            )}
            {hasBoostAvailable ? (
              <>
                <ImageWithDefault
                  id="boostStationArtwork"
                  data-testid="boost-station-artwork"
                  containerClassName={classNames(css.boostStationArtwork, {
                    [css.activeStation]: isBoostFeaturedInPlayer,
                  })}
                  className={classNames(css.boostStationImage)}
                  src={
                    isBoostFeaturedInPlayer
                      ? nowPlaying?.boost?.secondary?.image
                      : nowPlaying?.boost?.primary?.image
                  }
                  alt={nowPlaying?.boost?.secondary?.title}
                  disableLazyLoad
                />
                <ImageWithDefault
                  id="directoryStationArtwork"
                  data-testid="directory-station-artwork"
                  containerClassName={classNames(css.directoryStationArtwork, {
                    [css.inactiveStation]: isBoostFeaturedInPlayer,
                  })}
                  className={classNames(css.directoryStationImage)}
                  src={
                    isBoostFeaturedInPlayer
                      ? nowPlaying.primary.image
                      : nowPlaying.image
                  }
                  alt={nowPlaying.primary?.title}
                  disableLazyLoad
                />
              </>
            ) : (
              <ImageWithDefault
                id="playerArtwork"
                className={css.artworkImage}
                src={nowPlaying.image}
                alt={nowPlaying.title}
                disableLazyLoad
              />
            )}
          </div>
        </div>
        <div
          onMouseEnter={this.handleMouseEnter}
          className={css.titlesContainer}
          data-testid="nowPlayingTitles"
          ref={this.outerContainer}
        >
          <div ref={this.scrollInner} className={css.scrollContainer}>
            {isMediaAdLoaded ? (
              <>
                <div
                  id="playerTitle"
                  data-testid="audio-video-ad-player-title"
                  className={css.title}
                >
                  {getLocalizedText(MEDIA_AD_TITLE)}
                </div>
                <div id="playerSubtitle" className={css.subtitle}>
                  {getLocalizedText(MEDIA_AD_SUBTITLE)}
                </div>
              </>
            ) : (
              <>
                <div
                  id="playerTitle"
                  data-testid="now-playing-player-title"
                  className={css.title}
                >
                  {nowPlayingRejectReasonKey
                    ? getLocalizedText(nowPlayingRejectReasonKey)
                    : nowPlaying.title}
                </div>
                <div
                  id="playerSubtitle"
                  data-testid="now-playing-player-subtitle"
                  className={css.subtitle}
                >
                  {nowPlaying.subtitle}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );

    if (!isMobile && !isDesktopNowPlayingEnabled && nowPlayingPathname) {
      return (
        <Link className={css.link} to={nowPlayingPathname}>
          {nowPlayingNode}
        </Link>
      );
    }

    const linkClickHandler =
      isMobile ||
      isDiscord ||
      (!isNowPlayingDialogOpen && isDesktopNowPlayingEnabled)
        ? actions.openNowPlayingDialog
        : noop;

    return (
      <div className={css.link} onClick={linkClickHandler}>
        {nowPlayingNode}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isDesktopNowPlayingEnabled: selectIsDesktopNowPlayingEnabled(state),
    isNowPlayingDialogOpen: selectIsNowPlayingDialogOpen(state),
    isDiscord: selectIsDiscord(state),
    nowPlayingRejectReasonKey: selectNowPlayingRejectReasonKey(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        openNowPlayingDialog,
      },
      dispatch,
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NowPlaying);
